
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "path": "/home",
    "label": "Home",
    "icon": "account_balance",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/hospital",
    "label": "Hospital",
    "icon": "business",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/pharmacy",
    "label": "Pharmacy",
    "icon": "healing",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/laboratory",
    "label": "Laboratory",
    "icon": "local_bar",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/hospitalusers",
    "label": "Hospital Users",
    "icon": "local_library",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/laboratoryusers",
    "label": "Laboratory Users",
    "icon": "person_pin",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/pharmacyusers",
    "label": "Pharmacy Users",
    "icon": "person_pin_circle",
    "iconcolor": "",
    "target": "",
    "submenu": []
  },
  {
    "path": "/users",
    "label": "Address",
    "icon": "map",
    "iconcolor": "",
    "target": "",
    "submenu": []
  }
],
	hospitallistheader: [
  {
    "label": "Hospital",
    "align": "left",
    "sortable": false,
    "name": "hospital",
    "field": "hospital"
  },
  {
    "label": "Street",
    "align": "left",
    "sortable": false,
    "name": "street",
    "field": "street"
  },
  {
    "label": "Area",
    "align": "left",
    "sortable": false,
    "name": "area",
    "field": "area"
  },
  {
    "label": "Town",
    "align": "left",
    "sortable": false,
    "name": "town",
    "field": "town"
  },
  {
    "label": "District",
    "align": "left",
    "sortable": false,
    "name": "district",
    "field": "district"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	hospitaluserslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Photo",
    "align": "left",
    "sortable": false,
    "name": "photo",
    "field": "photo"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Telephone",
    "align": "left",
    "sortable": false,
    "name": "telephone",
    "field": "telephone"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	laboratorylistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Laboratory",
    "align": "left",
    "sortable": false,
    "name": "laboratory",
    "field": "laboratory"
  },
  {
    "label": "Street",
    "align": "left",
    "sortable": false,
    "name": "street",
    "field": "street"
  },
  {
    "label": "Area",
    "align": "left",
    "sortable": false,
    "name": "area",
    "field": "area"
  },
  {
    "label": "Town",
    "align": "left",
    "sortable": false,
    "name": "town",
    "field": "town"
  },
  {
    "label": "District",
    "align": "left",
    "sortable": false,
    "name": "district",
    "field": "district"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	permissionslistheader: [
  {
    "label": "Permission Id",
    "align": "left",
    "sortable": false,
    "name": "permission_id",
    "field": "permission_id"
  },
  {
    "label": "Permission",
    "align": "left",
    "sortable": false,
    "name": "permission",
    "field": "permission"
  },
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	pharmacylistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Pharmacy",
    "align": "left",
    "sortable": false,
    "name": "pharmacy",
    "field": "pharmacy"
  },
  {
    "label": "Street",
    "align": "left",
    "sortable": false,
    "name": "street",
    "field": "street"
  },
  {
    "label": "Area",
    "align": "left",
    "sortable": false,
    "name": "area",
    "field": "area"
  },
  {
    "label": "Town",
    "align": "left",
    "sortable": false,
    "name": "town",
    "field": "town"
  },
  {
    "label": "District",
    "align": "left",
    "sortable": false,
    "name": "district",
    "field": "district"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	roleslistheader: [
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "Role Name",
    "align": "left",
    "sortable": false,
    "name": "role_name",
    "field": "role_name"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	userslistheader: [
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Photo",
    "align": "left",
    "sortable": false,
    "name": "photo",
    "field": "photo"
  },
  {
    "label": "Createddatetime",
    "align": "left",
    "sortable": false,
    "name": "createddatetime",
    "field": "createddatetime"
  },
  {
    "label": "Area",
    "align": "left",
    "sortable": false,
    "name": "area",
    "field": "area"
  },
  {
    "label": "Town",
    "align": "left",
    "sortable": false,
    "name": "town",
    "field": "town"
  },
  {
    "label": "District",
    "align": "left",
    "sortable": false,
    "name": "district",
    "field": "district"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	viewmascountrydatalistheader: [
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Code",
    "align": "left",
    "sortable": false,
    "name": "code",
    "field": "code"
  }
],
	viewmasdepartmentslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Departments",
    "align": "left",
    "sortable": false,
    "name": "departments",
    "field": "departments"
  },
  {
    "label": "Short",
    "align": "left",
    "sortable": false,
    "name": "short",
    "field": "short"
  }
],
	viewmasindianpostaldatalistheader: [
  {
    "label": "Officename",
    "align": "left",
    "sortable": false,
    "name": "officename",
    "field": "officename"
  },
  {
    "label": "Pincode",
    "align": "left",
    "sortable": false,
    "name": "pincode",
    "field": "pincode"
  },
  {
    "label": "District",
    "align": "left",
    "sortable": false,
    "name": "district",
    "field": "district"
  },
  {
    "label": "Statename",
    "align": "left",
    "sortable": false,
    "name": "statename",
    "field": "statename"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  }
],

    exportFormats: {
        print: {
			label: 'Print',
			color: 'blue',
            icon: 'print',
            id: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			color: 'red',
            icon: 'picture_as_pdf',
            id: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
			color: 'green',
            icon: 'grid_on',
            id: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
			color: 'teal',
            icon: 'grid_on',
            id: 'csv',
            ext: 'csv',
        },
    },
    
}