
import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

import { useAuth } from 'src/composables/auth';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [{
	name: 'main',
	path: '/',
	component: () => import('layouts/MainLayout.vue'),
	children: [
		//Dashboard routes


//hospital routes
		{
			path: '/hospital/:fieldName?/:fieldValue?',
			name: 'hospitallist',
			component: () => import('pages/hospital/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/hospital/view/:id', 
			name: 'hospitalview', 
			component: () => import('pages/hospital/view.vue'), 
			props: true 
		},
		{ 
			path: '/hospital/add', 
			name: 'hospitaladd', 
			component: () => import('pages/hospital/add.vue'), 
			props: true 
		},
		{ 
			path: '/hospital/edit/:id', 
			name: 'hospitaledit', 
			component: () => import('pages/hospital/edit.vue'), 
			props: true 
		},

//hospitalusers routes
		{
			path: '/hospitalusers/:fieldName?/:fieldValue?',
			name: 'hospitaluserslist',
			component: () => import('pages/hospitalusers/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/hospitalusers/view/:id', 
			name: 'hospitalusersview', 
			component: () => import('pages/hospitalusers/view.vue'), 
			props: true 
		},
		{ 
			path: '/hospitalusers/add', 
			name: 'hospitalusersadd', 
			component: () => import('pages/hospitalusers/add.vue'), 
			props: true 
		},
		{ 
			path: '/hospitalusers/edit/:id', 
			name: 'hospitalusersedit', 
			component: () => import('pages/hospitalusers/edit.vue'), 
			props: true 
		},

//laboratory routes
		{
			path: '/laboratory/:fieldName?/:fieldValue?',
			name: 'laboratorylist',
			component: () => import('pages/laboratory/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/laboratory/view/:id', 
			name: 'laboratoryview', 
			component: () => import('pages/laboratory/view.vue'), 
			props: true 
		},
		{ 
			path: '/laboratory/add', 
			name: 'laboratoryadd', 
			component: () => import('pages/laboratory/add.vue'), 
			props: true 
		},
		{ 
			path: '/laboratory/edit/:id', 
			name: 'laboratoryedit', 
			component: () => import('pages/laboratory/edit.vue'), 
			props: true 
		},

//laboratoryusers routes
		{
			path: '/laboratoryusers/:fieldName?/:fieldValue?',
			name: 'laboratoryuserslist',
			component: () => import('pages/laboratoryusers/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/laboratoryusers/view/:id', 
			name: 'laboratoryusersview', 
			component: () => import('pages/laboratoryusers/view.vue'), 
			props: true 
		},
		{ 
			path: '/laboratoryusers/add', 
			name: 'laboratoryusersadd', 
			component: () => import('pages/laboratoryusers/add.vue'), 
			props: true 
		},
		{ 
			path: '/laboratoryusers/edit/:id', 
			name: 'laboratoryusersedit', 
			component: () => import('pages/laboratoryusers/edit.vue'), 
			props: true 
		},

//permissions routes
		{
			path: '/permissions/:fieldName?/:fieldValue?',
			name: 'permissionslist',
			component: () => import('pages/permissions/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/permissions/view/:id', 
			name: 'permissionsview', 
			component: () => import('pages/permissions/view.vue'), 
			props: true 
		},
		{ 
			path: '/permissions/add', 
			name: 'permissionsadd', 
			component: () => import('pages/permissions/add.vue'), 
			props: true 
		},
		{ 
			path: '/permissions/edit/:id', 
			name: 'permissionsedit', 
			component: () => import('pages/permissions/edit.vue'), 
			props: true 
		},

//pharmacy routes
		{
			path: '/pharmacy/:fieldName?/:fieldValue?',
			name: 'pharmacylist',
			component: () => import('pages/pharmacy/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/pharmacy/view/:id', 
			name: 'pharmacyview', 
			component: () => import('pages/pharmacy/view.vue'), 
			props: true 
		},
		{ 
			path: '/pharmacy/add', 
			name: 'pharmacyadd', 
			component: () => import('pages/pharmacy/add.vue'), 
			props: true 
		},
		{ 
			path: '/pharmacy/edit/:id', 
			name: 'pharmacyedit', 
			component: () => import('pages/pharmacy/edit.vue'), 
			props: true 
		},

//pharmacyusers routes
		{
			path: '/pharmacyusers/:fieldName?/:fieldValue?',
			name: 'pharmacyuserslist',
			component: () => import('pages/pharmacyusers/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/pharmacyusers/view/:id', 
			name: 'pharmacyusersview', 
			component: () => import('pages/pharmacyusers/view.vue'), 
			props: true 
		},
		{ 
			path: '/pharmacyusers/add', 
			name: 'pharmacyusersadd', 
			component: () => import('pages/pharmacyusers/add.vue'), 
			props: true 
		},
		{ 
			path: '/pharmacyusers/edit/:id', 
			name: 'pharmacyusersedit', 
			component: () => import('pages/pharmacyusers/edit.vue'), 
			props: true 
		},

//roles routes
		{
			path: '/roles/:fieldName?/:fieldValue?',
			name: 'roleslist',
			component: () => import('pages/roles/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/roles/view/:id', 
			name: 'rolesview', 
			component: () => import('pages/roles/view.vue'), 
			props: true 
		},
		{ 
			path: '/roles/add', 
			name: 'rolesadd', 
			component: () => import('pages/roles/add.vue'), 
			props: true 
		},
		{ 
			path: '/roles/edit/:id', 
			name: 'rolesedit', 
			component: () => import('pages/roles/edit.vue'), 
			props: true 
		},

//users routes
		{
			path: '/users/:fieldName?/:fieldValue?',
			name: 'userslist',
			component: () => import('pages/users/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/users/view/:id', 
			name: 'usersview', 
			component: () => import('pages/users/view.vue'), 
			props: true 
		},
		{ 
			path: '/index/register', 
			name: 'usersuserregister', 
			component: () => import('pages/index/userregister.vue'), 
			props: true 
		},
		{ 
			path: '/account/edit', 
			name: 'usersaccountedit', 
			component: () => import('pages/account/accountedit.vue'), 
			props: true 
		},
		{ 
			path: '/account', 
			name: 'usersaccountview', 
			component: () => import('pages/account/accountview.vue'), 
			props: true 
		},
		{ 
			path: '/users/add', 
			name: 'usersadd', 
			component: () => import('pages/users/add.vue'), 
			props: true 
		},
		{ 
			path: '/users/edit/:id', 
			name: 'usersedit', 
			component: () => import('pages/users/edit.vue'), 
			props: true 
		},

//viewmascountrydata routes
		{
			path: '/viewmascountrydata/:fieldName?/:fieldValue?',
			name: 'viewmascountrydatalist',
			component: () => import('pages/viewmascountrydata/list.vue'),
			props: route => passRouteToProps(route)
		},

//viewmasdepartments routes
		{
			path: '/viewmasdepartments/:fieldName?/:fieldValue?',
			name: 'viewmasdepartmentslist',
			component: () => import('pages/viewmasdepartments/list.vue'),
			props: route => passRouteToProps(route)
		},

//viewmasindianpostaldata routes
		{
			path: '/viewmasindianpostaldata/:fieldName?/:fieldValue?',
			name: 'viewmasindianpostaldatalist',
			component: () => import('pages/viewmasindianpostaldata/list.vue'),
			props: route => passRouteToProps(route)
		},

		
		
//Password reset routes
		{ 
			path: '/index/forgotpassword', 
			name: 'forgotpassword', 
			component: () => import('pages/index/forgotpassword.vue') 
		},
		{ 
			path: '/index/resetpassword', 
			name: 'resetpassword', 
			component: () => import('pages/index/resetpassword.vue') 
		},
		{ 
			path: '/index/resetpassword_completed', 
			name: 'resetpassword_completed', 
			component: () => import('pages/index/resetpassword_completed.vue') 
		},
		
		

		//Error pages
		{ path:  '/error/forbidden', name: 'forbidden',  component: () => import('pages/errors/forbidden.vue') },
		{ path: '/error/notfound',  name: 'notfound', component: () => import('pages/errors/pagenotfound.vue') }
	],
	
	},
	{ path: '/:catchAll(.*)*', component: () => import('pages/errors/pagenotfound.vue') }
];

export default route(async function () {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE == 'history' ? createWebHistory : createWebHashHistory)
	
	const mainRoute = routes.find(x => x.name = "main");
	
	const auth = useAuth();

	
	//get current user data from api on first load
	const { user } = await auth.getUserData();
	if(user){
		const homeRoute = {
			path: '/', 
			alias: '/home', 
			name: 'home', 
			component: () => import('pages/home/home.vue') 
		}
		mainRoute.children.push(homeRoute);
	}
	else{
		const indexRoute = { 
			path: '/',  
			alias: '/home', 
			name: 'index', 
			component: () => import('pages/index/index.vue') 
		}
		mainRoute.children.push(indexRoute);
	}

	const Router = createRouter({
	scrollBehavior(to, from, savedPostion){
		if(savedPostion) return savedPostion;
		return { top:0 }
	},
    routes,
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })
  return Router
})

